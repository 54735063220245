<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card>
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col sm="6" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('name')}" label="Name">
							<b-form-input v-model="models.name" v-validate="'required'" data-vv-name="name">
							</b-form-input>
							<div v-if="errors.has('name')">
								<p class="text-danger">{{ errors.first('name') }}</p>
							</div>
						</b-form-group>
						<b-form-group label="Status">
							<div class="col-sm-12 no-padding">
								<label class="radio-inline no-padding">
									<input type="radio" v-model="models.status" value="active">
									<span class="badge badge-success">Active</span>
								</label>
								<label class="radio-inline">
									<input type="radio" v-model="models.status" value="inactive">
									<span class="badge badge-danger">Inactive</span>
								</label>
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="6" class="mb-2">
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('menu')}" label="Choose Menu">
							<input type="hidden" v-model="models.menu_ids" v-validate="'required'" data-vv-name="menu"/>
							<div v-if="errors.has('menu')">
								<p class="text-danger">{{ errors.first('menu') }}</p>
							</div>
							<ul class="tree_menu no-padding-left">
								<template v-for="(v,k) in masters.menu">
									<li :key="k">
										<label class="radio-inline no-padding-left">
											<input type="checkbox" 
													v-model="v.is_select" 
													v-bind:true-value="1" 
													v-bind:false-value="0"
													@click="toogleSelect(v.id)">
											{{v.name}}
										</label>
										<template v-if="v.children.length">
											<ul>
												<template v-for="(v_sub, k_sub) in v.children">
													<li :key="k_sub">
														<label class="radio-inline no-padding-left">
															<input type="checkbox" 
																v-model="v_sub.is_select" 
																v-bind:true-value="1" 
																v-bind:false-value="0"
																@change="toggleSubSelect(v)">
															{{v_sub.name}}
														</label>
													</li>
												</template>
											</ul>
										</template>
									</li>
								</template>
							</ul>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
	</form>
</template>
<script>
	import api from '@/utils/api.js';
	import ResponseAlert from '@/components/response_alert';

	export default {
		name: 'FormRole',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					name: "",
					status: "active",
					menu_ids: [],
				},
				masters: {
					menu: []
				},
				vars: {

				}
			}
		},
		created() {
			const _ = this
			_.getById();
		},
		methods: {
			async getById() {
				const _ = this;
				try {
					if (_.$route.params.id) {
						const request = await _.$axios.get(`${api.role}/${_.$route.params.id}`);
						const { data } = request.data
						_.models.name = data.name;
						_.models.status = data.status;
						_.models.menu_ids = data.menu_ids;
					}
					_.getMenu();
				} catch (error) {
					_.$router.push({ name: 'page404' });
				}
				
			},
			getMenu() {
				const _ = this
				_.$axios.get(api.menu, {
					params: {
						orderby: 'sequence',
						sort: 'asc'
					}
				}).then(res => {
					res.data.data.map(v => {
						let children = [];
						let selected = 0;
						if(v.children){
							v.children.map(i => {
								const is_select = _.models.menu_ids.includes(i.id);
								children.push({
									id: i.id,
									name: i.name,
									parent_id: i.parent_id,
									is_select: is_select ? 1 : 0,
								});
								if(is_select){
									selected += 1;
								}
							});
						}

						// Route /article is specific for contributor not admin
						// ROute /article is hidden in role menu list
						if(v.url !== '/article'){
							_.masters.menu.push({
								id: v.id,
								name: v.name,
								parent_id: v.parent_id,
								is_select: ((v.children.length && 
											_.models.menu_ids.includes(v.id) && 
											selected === v.children.length ) || 
											(!v.children.length && 
											_.models.menu_ids.includes(v.id))) ? 1 : 0,
								children : children
							});
						}
					});
				});
			},
			toogleSelect(id){
				const _ = this;
				for(const v of _.masters.menu){
					const is_select = v.is_select === 1 ? 0 : 1;
					if(v.id == id){
						v.children.map( v_sub => {
							v_sub.is_select = is_select;
						});
					}
				}
			},
			toggleSubSelect(parent){
				parent.is_select = 0;
				const is_all_children_uncheck = Boolean(parent.children.map(children => children.is_select == 1).includes(false))
				parent.is_select = is_all_children_uncheck ? 0 : 1;	
			},
      checkSubMenuSelected(children){
				let selected = false;
				for(const v of children){
					if(Number(v.is_select) === 1){
						selected = true;
						break;
					}
				}
				return selected;
			},
			setMenuBeforeSave() {
				const _ = this;
				_.models.menu_ids = [];
				for(const v of _.masters.menu) {
					if(Number(v.is_select) === 1 || (v.children.length && _.checkSubMenuSelected(v.children))){
						_.models.menu_ids.push(v.id);
					}
					if(v.children){
						for(const v_sub of v.children) {
							if(Number(v_sub.is_select) === 1){
								_.models.menu_ids.push(v_sub.id);
							}
						}
					}
				}
			},
			save() {
				const _ = this;
				_.setMenuBeforeSave();
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"
						if (!_.$route.params.id) {
							request = _.$axios.post(api.role, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.role}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} role`,)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>